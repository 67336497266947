document.addEventListener('DOMContentLoaded', () => {

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
  
    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {
  
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);
  
        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
  
      });
    });
  
  const buttonNewsletter = document.getElementById('inscri-nl-btn');
  //console.log(buttonNewsletter);
  buttonNewsletter.addEventListener('click', () => {
    const email = document.getElementsByClassName('input input-newsletter')[0].value;
    if (email) {
      //console.log(email); 
      window.location.href = `/newsletter.html?email=${window.btoa(email)}`;
    } else {
      window.location.href = '/newsletter.html';
    }

  })

  // Navbar
  const setNavbarVisibility = function setNavbarVisibility() {
    const navbar = document.getElementById("navbar");

    if (!navbar) {
      return;
    }
    const cs = getComputedStyle(navbar);
    const paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
    const brand = navbar.querySelector(".navbar-brand");
    //const original = document.getElementById("navbarBasicExample"); 
    //const clone = document.getElementById("navbarStartClone");

    const rest = navbar.clientWidth - paddingX - brand.clientWidth;

    const all = document.querySelectorAll("#navbarStartClone > .navbar-start > .bd-navbar-item");
    const base = document.querySelectorAll("#navbarStartClone > .navbar-start > .bd-navbar-item-base");
    const more = document.querySelectorAll("#navbarBasicExample > .navbar-start > .bd-navbar-item-more");
    const plusplus = document.querySelectorAll("#navbarBasicExample > .navbar-start > .bd-plusplus");
    const dropdown = document.querySelectorAll("#navbarBasicExample .bd-navbar-dropdown > .navbar-item");

    let count = 0;
    let totalWidth = 0;

    //const showMore = function showMore() {};

    //const hideMore = function hideMore() {};

    let _iteratorNormalCompletion = true;
    let _didIteratorError = false;
    let _iteratorError = undefined;

    let _iterator;
    let _step;
    try {
      for (_iterator = all[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        let item = _step.value;

        totalWidth += item.offsetWidth;

        if (totalWidth > rest) {
          break;
        }

        count++;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          //throw _iteratorError;
        }
      }
    }

    const howManyMore = Math.max(0, count - base.length);
    //console.log(howManyMore)

    if (howManyMore > 0) {
      for (let i = 0; i < howManyMore; i++) {
        more[i].classList.add("bd-is-visible");
        dropdown[i].classList.add("bd-is-hidden");
      }

    }

    /*if(howManyMore < more.length) {
      plusplus[0].classList.remove("bd-is-hidden");
    } else {
      plusplus[0].classList.add("bd-is-hidden");  
    }*/

    for (let j = howManyMore; j < more.length; j++) {
      more[j].classList.remove("bd-is-visible");
    }

    for (let k = howManyMore; k < dropdown.length; k++) {
      dropdown[k].classList.remove("bd-is-hidden");
    }

  };
  setNavbarVisibility();

  // Events

  let resizeTimer = void 0;

  function handleResize() {
    window.clearTimeout(resizeTimer);

    resizeTimer = window.setTimeout(function () {
      setNavbarVisibility();
    }, 10);
  }

  window.addEventListener("resize", handleResize);

  // When the user scrolls down 20px from the top of the document, show the button
  
  function scrollFunction() {
    const myTopBtn = document.getElementById('myTopBtn')
    //console.log(document.body.scrollTop, document.documentElement.scrollTop)
    if (document.documentElement.scrollTop > 1000) {
      myTopBtn.setAttribute("style", "display: inline;");
      myTopBtn.classList.add("myTopBtn-visible");
      myTopBtn.classList.remove("myTopBtn-hidden");
    } else {
      myTopBtn.classList.remove("myTopBtn-visible");
      myTopBtn.classList.add("myTopBtn-hidden");
    }
  }

  window.addEventListener("scroll", scrollFunction);

  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const buttonGoToTop = document.getElementById('myTopBtn');
  buttonGoToTop.addEventListener("click", topFunction);


});



// This example requires the Places library. Include the libraries=places
// parameter when you first load the API. For example:
// <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places">
function initMap() {
  const map = new google.maps.Map(document.getElementById("map"), {
    center: { lat: 48.275, lng: -1.540 },
    zoom: 15,
  });
  const request = {
    placeId: "ChIJ49mQQuHFDkgRTtXSpT71rQk",
    fields: ["name", "formatted_address", "geometry"],
  };
  const infowindow = new google.maps.InfoWindow();
  const service = new google.maps.places.PlacesService(map);

  service.getDetails(request, (place, status) => {
    if (
      status === google.maps.places.PlacesServiceStatus.OK &&
      place &&
      place.geometry &&
      place.geometry.location
    ) {
      const marker = new google.maps.Marker({
        map,
        position: place.geometry.location,
      });

      google.maps.event.addListener(marker, "click", () => {
        const content = document.createElement("div");
        const nameElement = document.createElement("h3");
        nameElement.className = "h3-bouillon-place"

        nameElement.textContent = place.name;
        content.appendChild(nameElement);

        const placeIdElement = document.createElement("p");

        placeIdElement.textContent = place.place_id;
        content.appendChild(placeIdElement);

        const placeAddressElement = document.createElement("p");

        placeAddressElement.textContent = place.formatted_address;
        content.appendChild(placeAddressElement);
        infowindow.setContent(content);
        infowindow.open(map, marker);
      });
    }
  });
}

window.initMap = initMap;

